<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Company Settings
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Company Details
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateCompanySetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 70vh; position: relative"
      >
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.company_name"
                dense
                filled
                label="Company name"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.phone_number"
                dense
                filled
                label="Phone number"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.whatsapp_number"
                dense
                filled
                label="Whatsapp number"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.website_url"
                dense
                filled
                label="Website URL"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.email_address"
                dense
                filled
                label="Email address"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.street_1"
                dense
                filled
                label="Street 1"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.street_2"
                dense
                filled
                label="Street 2"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.landmark"
                dense
                filled
                label="Landmark"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.zip_code"
                dense
                filled
                label="Zip code"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="mt-4 pb-4 custom-border-top">
              <h3
                class="font-weight-700 custom-headline color-custom-blue py-4"
              >
                Regional Settings
              </h3>
              <v-row>
                <v-col md="4">
                  <v-select
                    v-model="setting.date_format"
                    :items="date_format_list"
                    label="Date format"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="py-2"
                  ></v-select>
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="setting.time_format"
                    :items="time_format_list"
                    label="Time format"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="py-2"
                  ></v-select>
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="setting.weekday"
                    :items="weekdayList"
                    label="First day of the week"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="py-2"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="12" class="mt-4 pb-4 custom-border-top">
              <h3
                class="font-weight-700 custom-headline color-custom-blue py-4"
              >
                Business Hours
              </h3>
              <div>
                <table class="width-100">
                  <tbody>
                    <tr
                      v-for="(day, index) in weekDays"
                      :key="index"
                      :class="{
                        'business-hours-disabled': !day.status,
                      }"
                    >
                      <td width="200" class="font-weight-600 font-size-16">
                        {{ day.title }}
                      </td>
                      <td width="200">
                        <v-switch
                          v-model.trim="day.status"
                          inset
                          hide-details
                          color="cyan"
                        ></v-switch>
                      </td>
                      <td
                        class="px-4"
                        :class="{ 'pointer-events-none': !day.status }"
                      >
                        <TimePickerTemplate
                          v-on:update:time-picker="
                            updateTime('start', index, $event)
                          "
                          :defaultTime.sync="day.start_time"
                          placeholder="Start Time"
                        ></TimePickerTemplate>
                      </td>
                      <td
                        class="px-4"
                        :class="{ 'pointer-events-none': !day.status }"
                      >
                        <TimePickerTemplate
                          v-on:update:time-picker="
                            updateTime('end', index, $event)
                          "
                          :defaultTime.sync="day.end_time"
                          placeholder="End Time"
                        ></TimePickerTemplate>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </perfect-scrollbar>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import TimePickerTemplate from "@/view/pages/partials/Timepicker";
import { GET, PATCH } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "profile-company-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        company_name: null,
        phone_number: null,
        whatsapp_number: null,
        website_url: null,
        email_address: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        date_format: null,
        time_format: null,
        weekday: null,
      },
      time_format_list: [
        {
          value: "HH:mm",
          text: "24 Hour (" + moment().format("HH:mm") + ")",
        },
        {
          value: "hh:mm A",
          text: "12 Hour (" + moment().format("hh:mm A") + ")",
        },
      ],
      date_format_list: [
        {
          value: "MMMM Do YYYY",
          text: moment().format("MMMM Do YYYY"),
        },
        {
          value: "Do MMMM YYYY",
          text: moment().format("Do MMMM YYYY"),
        },
        {
          value: "MMM Do YY",
          text: moment().format("MMM Do YY"),
        },
        {
          value: "Do MMM YY",
          text: moment().format("Do MMM YY"),
        },
        {
          value: "DD/MM/YYYY",
          text: moment().format("DD/MM/YYYY"),
        },
        {
          value: "MM/DD/YYYY",
          text: moment().format("MM/DD/YYYY"),
        },
        {
          value: "YYYY-DD-MM",
          text: moment().format("YYYY-DD-MM"),
        },
      ],
      weekdayList: [
        {
          value: 1,
          text: "Sunday",
        },
        {
          value: 2,
          text: "Monday",
        },
      ],
      weekDays: [],
    };
  },
  methods: {
    getCompanySetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/company" })
        .then(({ data }) => {
          _this.setting = data;
          _this.weekDays = data.business_hours;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateCompanySetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.business_hours = _this.weekDays;
      _this.$store
        .dispatch(PATCH, { url: "setting/company", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateTime(type, index, value) {
      if (this.weekDays[index]) {
        if (type == "start") {
          this.weekDays[index].start_time = value;
        }
        if (type == "end") {
          this.weekDays[index].end_time = value;
        }
      }
    },
  },
  components: {
    TimePickerTemplate,
  },
  mounted() {
    this.getCompanySetting();
  },
};
</script>
